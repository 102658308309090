<template>
  <div class="app-wrap">
    <el-container class="app-container">
      <el-main>
        <el-table :data="tableData">
          <el-table-column type="expand">
            <template #default="{ row }">
              <el-descriptions class="expand-padding">
                <el-descriptions-item
                  v-if="row.slogan"
                  label="标语："
                  :span="3"
                >{{ row.slogan }}</el-descriptions-item>
                <el-descriptions-item label="证件号码：">{{ row.id_card }}</el-descriptions-item>
                <el-descriptions-item label="地址：" :span="2">
                  {{ row.province }}
                  -
                  {{ row.city }}
                  -
                  {{ row.area }}
                  -
                  {{ row.address }}
                </el-descriptions-item>
                <el-descriptions-item label="服务次数：">{{ row.service_number }}</el-descriptions-item>
                <el-descriptions-item label="评分：">{{ row.score }}</el-descriptions-item>
                <el-descriptions-item label="经验值：">{{ row.exp }}</el-descriptions-item>
                <el-descriptions-item
                  v-if="row.status === 1" label="入驻时间："
                >
                  {{ row.register_date }}
                </el-descriptions-item>
                <el-descriptions-item
                  v-if="row.status === 2"
                  label="拒绝原因："
                >
                  {{ row.examine_fail_reason }}
                </el-descriptions-item>
              </el-descriptions>
            </template>
          </el-table-column>
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="地区" prop="province"></el-table-column>
          <el-table-column label="启用/禁用" align="center" width="82">
            <template #default="{ row }">
              <el-popover
                v-model:visible="row.visible"
                placement="bottom"
                :title="`确认修改为${row.is_enable === 1 ? '禁用' : '启用'}？`"
                :width="160"
              >
                <el-button size="mini" @click="row.visible = false">取消</el-button>
                <el-button size="mini" type="primary" @click="setIsEnable(row.id)">确定</el-button>
                <template #reference>
                  <el-button
                    v-if="row.is_enable === 1"
                    type="text"
                    class="color-success"
                    :icon="Edit"
                    @click="row.visible = true"
                  >启用</el-button>
                  <el-button
                    v-else
                    type="text"
                    class="color-info"
                    :icon="Edit"
                    @click="row.visible = true"
                  >禁用</el-button>
                </template>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center" width="82">
            <template #default="{ row }">
              <el-tag v-if="row.status === -1" type="info">未认证</el-tag>
              <el-tag v-else-if="row.status === 0" type="warning">待审核</el-tag>
              <el-tag v-else-if="row.status === 1" type="success">审核通过</el-tag>
              <el-tooltip
                v-else-if="row.status === 2"
                placement="top"
                :content="row.examine_fail_reason"
              >
                <el-tag type="danger">审核失败</el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="118">
            <template #default="{ row }">
              <el-button
                v-if="row.status === 0"
                type="primary"
                @click="openDetail(row.id)"
              >审核</el-button>
              <el-button
                v-else
                type="text"
                :icon="View"
                @click="openDetail(row.id)"
              >查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination v-model="page" @change="getList"></tj-pagination>
      </el-footer>
    </el-container>
  </div>
  <EditDialog ref="editDialog" @submit="getList"></EditDialog>
</template>

<script setup>
import { map } from 'lodash';
import { ref, onMounted } from 'vue';
import {
  View,
  Phone,
  Edit,
} from '@element-plus/icons-vue';
import { usePagination } from '@tj/ui';
import EditDialog from './edit.vue';
import request from '@/hooks/request';

const { page } = usePagination();
const tableData = ref([]);

function getList() {
  request.get('/admin/engineer/user', {
    params: {
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = map(data.list, (item) => ({
      ...item,
      visible: false,
    }));
    page.total = data.total;
  });
}

const editDialog = ref();

function openDetail(userId) {
  editDialog.value.open(userId);
}

function setIsEnable(userId) {
  request.post(`/admin/engineer/setIsEnable/${userId}`).then(() => {
    getList();
  });
}

onMounted(() => {
  getList();
});
</script>
